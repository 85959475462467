import { useStaticQuery, graphql } from "gatsby";

export const useBecomeAFunderHeadingSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Become A Funder" } }) {
        becomeAFunder {
          becomeAFunderHeadingSection {
            heading
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, height: 300, width: 1440)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.becomeAFunder.becomeAFunderHeadingSection;
};
