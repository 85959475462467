import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import IsrButton from "../IsrButton/IsrButton";
import warningIcon from "../../assets/images/warning-icon.png";
import "./Jumbotron.scss";

const Jumbotron = ({ media, dynamicPanelMessage, goBack, title, subtitle }) => {
  return (
    <div
      className={`isr-jumbotron ${
        dynamicPanelMessage ? "hasDynamicPanel" : ""
      } ${goBack ? "hasBackButton" : ""}`}
    >
      {media?.localFile?.childImageSharp?.gatsbyImageData ? (
        <GatsbyImage
          className="jumbotron-background"
          image={media.localFile.childImageSharp.gatsbyImageData}
          alt={title}
        />
      ) : (
        <div className="jumbotron-background" />
      )}

      {goBack && (
        <div className="container buttonContainer">
          <Link to={goBack.url}>
            <IsrButton variant="arrow" size="md">
              Back to {goBack.title || null}
            </IsrButton>
          </Link>
        </div>
      )}

      <div className="jumbotron-content">
        {title && <h1 className="h2">{title}</h1>}
        {subtitle && <h2 className="h3">{subtitle}</h2>}

        {dynamicPanelMessage && (
          <div className="container">
            <div className="dynamicPanel">
              <img src={warningIcon} alt="warningIcon" />
              <div // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: dynamicPanelMessage,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Jumbotron.defaultProps = {
  title: "",
  subtitle: "",
  goBack: null,
  media: null,
};

Jumbotron.propTypes = {
  media: PropTypes.shape({
    localFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
      }),
    }),
  }),
  title: PropTypes.string,
  goBack: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  // eslint-disable-next-line react/require-default-props
  dynamicPanelMessage: PropTypes.string,
  subtitle: PropTypes.string,
};
export default Jumbotron;
