import { useStaticQuery, graphql } from "gatsby";
export const useBecomeAFunderContactTodaySection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Become A Funder" } }) {
        becomeAFunder {
          becomeAFunderContactTodaySection {
            title
            content
            buttonLinkUrl {
              title
              url
            }
          }
        }
      }
    }
  `);
  return data.wpPage.becomeAFunder.becomeAFunderContactTodaySection;
};
