/* eslint-disable react/no-danger */
import React from "react";
import Jumbotron from "../Jumbotron/Jumbotron";
import Spacer from "../Spacer/Spacer";
import IsrButton from "../IsrButton/IsrButton";
import IsrLink from "../IsrLink/IsrLink";
import { useBecomeAFunderHeadingSection } from "../../hooks/useBecomeAFunder/useBecomeAFunderHeadingSection";
import { useBecomeAFunderTopContent } from "../../hooks/useBecomeAFunder/useBecomeAFunderTopContent";
import { useBecomeAFunderContactTodaySection } from "../../hooks/useBecomeAFunder/useBecomeAFunderContactTodaySection";
import { useBecomeAFunderBottomContent } from "../../hooks/useBecomeAFunder/useBecomeAFunderBottomContent";
import "./BecomeAPartnerSection.scss";

const BecomeAPartnerSection = () => {
  const { heading, backgroundImage } = useBecomeAFunderHeadingSection();
  const { becomeAFunderTopContent } = useBecomeAFunderTopContent();
  const {
    title,
    content,
    buttonLinkUrl: buttonLinkUrl1,
  } = useBecomeAFunderContactTodaySection();
  const {
    title: title2,
    content: content2,
    buttonLinkUrl: buttonLinkUrl2,
  } = useBecomeAFunderBottomContent();

  return (
    <section className="becomeAFunderSection">
      <Jumbotron media={backgroundImage} title={heading} />
      <div className="container">
        <Spacer mobileSize={20} size={40} />
        {becomeAFunderTopContent && (
          <div className="row">
            <div className="col-md-2" />
            <div
              className="col-md-8 content-wrapper"
              dangerouslySetInnerHTML={{
                __html: becomeAFunderTopContent,
              }}
            />
            <div className="col-md-2" />
          </div>
        )}
        <Spacer mobileSize={20} size={40} />
        <div className="contactTodayContainer">
          {title && <h2>{title}</h2>}
          <div>
            {content && (
              <div
                className="content-wrapper"
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            )}
            {buttonLinkUrl1 && buttonLinkUrl1.title && (
              <IsrLink link={buttonLinkUrl1}>
                <IsrButton type="button" variant="primary" size="md">
                  {buttonLinkUrl1.title}
                </IsrButton>
              </IsrLink>
            )}
          </div>
        </div>
        <Spacer mobileSize={20} size={60} />
        {title2 && <h2>{title2}</h2>}
        <Spacer mobileSize={10} size={20} />
        {content2 && (
          <div className="row">
            <div className="col-md-2" />
            <div
              className="col-md-8 content-wrapper"
              dangerouslySetInnerHTML={{
                __html: content2,
              }}
            />
            <div className="col-md-2" />
          </div>
        )}
        <Spacer mobileSize={10} size={20} />
        {buttonLinkUrl2 && buttonLinkUrl2.title && (
          <>
            <IsrLink link={buttonLinkUrl2}>
              <IsrButton type="button" variant="primary" size="md">
                {buttonLinkUrl2.title}
              </IsrButton>
            </IsrLink>
            <Spacer mobileSize={30} size={80} />
          </>
        )}
      </div>
    </section>
  );
};

export default BecomeAPartnerSection;
