import { useStaticQuery, graphql } from "gatsby";
export const useBecomeAFunderBottomContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Become A Funder" } }) {
        becomeAFunder {
          becomeAFunderBottomContent {
            title
            content
            buttonLinkUrl {
              title
              url
            }
          }
        }
      }
    }
  `);
  return data.wpPage.becomeAFunder.becomeAFunderBottomContent;
};
